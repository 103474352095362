<template>
  <div>
    <VariantModalDialog
      v-model="isShown"
      variant="bottom"
      class="font-bold text-lg fixed bottom-0 w-full rounded-t-2xs rounded-b-none z-30 pt-5 pb-8"
    >
      <component :is="ExpandedCompareMenu" v-if="isShown && skus.length" />
    </VariantModalDialog>
    <Transition name="slide-up">
      <nav
        v-if="!isShown && skus.length && isOpen"
        key="compare-button"
        type="button"
        :title="$t('compare')"
        class="font-bold text-lg fixed bottom-0 w-full shadow-custom-7 rounded-t-2xs rounded-b-none z-30 py-5 [ bg-white ] px-5 md:px-0"
      >
        <div class="container mx-auto flex items-center justify-start gap-x-4">
          <button class="[ text-primary-800 font-display ] [ flex items-center gap-x-2 ]" @click="isShown = true">
            <svg-icon name="compare" class="w-7 h-7" />
            <span class="">{{ $t('compare') }}</span>
            <span class="text-sm font-bold">{{ $t('count', [skus.length]) }}</span>
          </button>

          <button class="[ text-red-700 font-body ] [ flex items-center gap-x-2 ]" @click="clearAll">
            <svg-icon name="trash" class="w-5 h-5" />
            <span class="underline font-normal">{{ $t('clear') }}</span>
          </button>
        </div>
      </nav>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useCompareOverlayStore } from '~/composables/compare';

const route = useRoute();
const ExpandedCompareMenu = defineAsyncComponent(() => import('./ExpandedCompareMenu.vue'));
const { isOpen } = useCompareOverlayStore();
const { skus } = useComparisonState();
const isShown = ref(false);
const clearAll = () => {
  compareClearItems();
  isShown.value = false;
};

watch(skus, value => {
  if (!value.length) {
    isShown.value = false;
  }
});

watch(route, () => {
  isShown.value = false;
});

const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style lang="postcss" scoped>
img {
  max-height: 90px;
}

.ComparisonMenuCard {
  @screen lg {
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto auto;
  }
  &__link {
    @screen lg {
      width: calc(100% - 40px);
    }
  }
}

.min-h-sm {
  min-height: 120px;
}
</style>

<i18n>
{
  "en": {
    "compare": "Compare Products",
    "count": "({0}/4)",
    "viewComparison": "View Comparison",
    "clear": "Clear All"
  },
  "ar": {
    "compare": "قارن المنتجات",
    "count": "{0} من 4",
    "viewComparison": "عرض المقارنة",
    "clear": "مسح المنتجات"
  }
}
</i18n>
